import {
  Grid2 as Grid,
  Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  TextField,
  Switch,
  IconButton
} from "@mui/material";
import {
  Close as CloseIcon
} from "@mui/icons-material";
import { modelList, imageModelList } from "../utils/Config";

type Model = (typeof modelList[number])["value"];
type ImageModel = (typeof imageModelList[number])["value"];

interface SettingsDialogProps {
  isImage: boolean;
  settingsDialogOpen: boolean;
  setSettingsDialogOpen: (settingsDialogOpen: boolean) => void;
  selectedModel: Model;
  setSelectedModel: (model: Model) => void;
  selectedImageModel: ImageModel;
  setSelectedImageModel: (model: ImageModel) => void;
  historyCount: number;
  setHistoryCount: (historyCount: number) => void;
  isStream: boolean;
  setIsStream: (isStream: boolean) => void;
}

function SettingsDialog({
  isImage,
  settingsDialogOpen,
  setSettingsDialogOpen,
  selectedModel,
  setSelectedModel,
  selectedImageModel,
  setSelectedImageModel,
  historyCount,
  setHistoryCount,
  isStream,
  setIsStream
}: SettingsDialogProps): JSX.Element {

  return (
    <Dialog
      open={settingsDialogOpen}
      onClose={() => setSettingsDialogOpen(false)}
      aria-hidden={settingsDialogOpen ? "false" : "true"}
    >
      <DialogTitle>
        {"設定"}
      </DialogTitle>
      <DialogContent>
        {isImage ? (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid size="auto">
              <FormControl>
                <FormLabel>画像生成AIモデル</FormLabel>
                <RadioGroup
                  value={selectedImageModel}
                  onChange={e => setSelectedImageModel(e.target.value)}
                >
                  {imageModelList.map(imageModel => (
                    <FormControlLabel
                      key={imageModel.value}
                      value={imageModel.value}
                      control={<Radio />}
                      label={imageModel.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid size="auto">
              <FormControl>
                <FormLabel>生成AIモデル</FormLabel>
                <RadioGroup
                  value={selectedModel}
                  onChange={e => setSelectedModel(e.target.value)}
                >
                  {modelList.map(model => (
                    <FormControlLabel
                      key={model.value}
                      value={model.value}
                      control={<Radio />}
                      label={model.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid size="auto"
              sx={{ mt: { xs: 2, md: 0 } }}
            >
              <Grid size="auto">
                <TextField
                  type="number"
                  value={historyCount}
                  onChange={e => setHistoryCount(Number(e.target.value))}
                  error={historyCount < 0}
                  helperText={"0以上"}
                  label="送信する履歴数"
                  slotProps={{
                    input: {
                      inputProps: {
                        min: 0
                      }
                    },
                    inputLabel: {
                      shrink: true
                    }
                  }}
                />
              </Grid>
              <Grid size="auto"
                sx={{ mt: 2 }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={isStream}
                      onChange={e => setIsStream(e.target.checked)}
                    />
                  }
                  label="ストリーム出力"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Tooltip arrow
          title="閉じる"
        >
          <IconButton
            onClick={() => setSettingsDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

export default SettingsDialog;
