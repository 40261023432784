import {
  Container,
  Card, CardMedia,
  Skeleton
} from "@mui/material";

interface ImageProps {
  isRunning: boolean;
  imageUrl: string;
};

function Image({
  isRunning,
  imageUrl
}: ImageProps): JSX.Element {
    return (
    <Container maxWidth="sm"
      sx={{ flexGrow: 1, overflow: "auto", my: 12 }}
    >
      {imageUrl ? (
        <Card>
          <CardMedia
            component="img"
            image={imageUrl}
          />
        </Card>
      ) : (
        <Skeleton
          variant="rectangular"
          height={360}
          animation={isRunning ? "wave" : false}
          sx={{ my: 2 }}
        />
      )}
    </Container>
  );
}

export default Image;
