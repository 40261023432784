import { useState } from "react";
import {
  AppBar, Toolbar, Container,
  Box, Tooltip,
  TextField, IconButton, CircularProgress
} from "@mui/material";
import {
  Send as SendIcon
} from "@mui/icons-material";
import { modelList, imageModelList } from "../utils/Config";

type Role = "system" | "user";
type History = {
  role: Role;
  content: string;
};
type Model = (typeof modelList[number])["value"];
type ImageModel = (typeof imageModelList[number])["value"];
type Severity = "success" | "info" | "warning" | "error";
interface InputProps {
  isRunning: boolean;
  setIsRunning: React.Dispatch<React.SetStateAction<boolean>>;
  isImage: boolean;
  historyList: History[];
  setHistoryList: React.Dispatch<React.SetStateAction<History[]>>;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  selectedModel: Model;
  selectedImageModel: ImageModel;
  historyCount: number;
  isStream: boolean;
  setSettingsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageText: React.Dispatch<React.SetStateAction<string>>;
  setMessageSeverity: React.Dispatch<React.SetStateAction<Severity>>;
  setMessageOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function InputAppBar({
  isRunning,
  setIsRunning,
  isImage,
  historyList,
  setHistoryList,
  setImageUrl,
  selectedModel,
  selectedImageModel,
  historyCount,
  isStream,
  setSettingsDialogOpen,
  setMessageText,
  setMessageSeverity,
  setMessageOpen
}: InputProps): JSX.Element {
  // 入力された文章
  const [input, setInput] = useState<string>("");

  // エラーなどのメッセージを表示する
  const createMessage = (text: string, severity: Severity) => {
    setMessageText(text);
    setMessageOpen(true);
    setMessageSeverity(severity);
  }

  const updateHistory = (accumulatedResponse: string) => {
    setHistoryList(prev => {
      const newList = [...prev];
      if (newList[newList.length - 1].role === "system") {
        newList[newList.length - 1].content = accumulatedResponse;
      } else {
        newList.push({
          role: "system",
          content: accumulatedResponse,
        });
      }
      return newList;
    });
  };

  // 入力された文章を送信する
  const send = async () => {
    if (!input.trim()) {
      createMessage("文章を入力してください。", "error");
      return;
    }
    if (historyCount < 0) {
      setSettingsDialogOpen(true);
      createMessage("送信する履歴数は0以上にしてください。", "error");
      return;
    }
    setIsRunning(true);

    const selectedHistoryList = historyCount >= 1 ? historyList.slice(-(historyCount * 2)) : [];
    const selectedMessages = [...selectedHistoryList, { role: "user", content: input }];
    try {
      const response = await fetch("/api/text", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedModel,
          selectedMessages,
          isStream,
        }),
      });

      if (!response.ok) {
        throw new Error("文章生成エラー");
      }

      if (isStream) {
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        const userHistory: History = {
          role: "user",
          content: input,
        };
        setHistoryList(prev => [...prev, userHistory]);

        let accumulatedResponse = "";

        while (true) {
          const { done, value } = await reader!.read();
          if (done) break;
          const chunk = decoder.decode(value);

          const lines = chunk.split("\n");
          for (const line of lines) {
            if (line.startsWith("data: ")) {
              const jsonStr = line.slice(6);
              if (jsonStr === "[DONE]") break;
              try {
                const data = JSON.parse(jsonStr);
                if (data.response) {
                  accumulatedResponse += data.response;
                  updateHistory(accumulatedResponse);
                }
              } catch (e) {
                createMessage("JSONの解析に失敗。", "error");
              }
            }
          }
        }
      } else {
        const result = await response.json();

        const userHistory: History = {
          role: "user",
          content: input,
        };
        const modelHistory: History = {
          role: "system",
          content: result.text,
        };
        setHistoryList(prev => [...prev, userHistory, modelHistory]);
      }

      setInput("");
    } catch (error) {
      createMessage(String(error), "error");
    } finally {
      setIsRunning(false);
    }
  };

  // 入力された文章を送信し、画像生成する
  const sendImage = async () => {
    if (!input.trim()) {
      createMessage("文章を入力してください。", "error");
      return;
    }
    setIsRunning(true);

    try {
      const response = await fetch("/api/image", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedImageModel,
          input
        }),
      });

      if (!response.ok) {
        throw new Error("画像生成エラー");
      }

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setImageUrl(imageUrl);

      //setInput("");
    } catch (error) {
      setImageUrl("");
      createMessage(String(error), "error");
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <AppBar position="sticky" color="inherit"
      sx={{ top: "auto", bottom: 0 }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ px: 0 }}>
          <TextField
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (isImage) {
                  sendImage();
                } else {
                  send();
                }
              }
            }}
            disabled={isRunning}
            required
            multiline
            maxRows={9}
            size="small"
            fullWidth
            sx={{ py: 2 }}
          />

          <Box sx={{ ml: 1, alignSelf: "flex-end" }}>
            <Tooltip arrow
              title="送信"
            >
              <Box sx={{ position: "relative" }}>
                <IconButton
                  onClick={isImage ? sendImage : send}
                  disabled={isRunning || !input.trim()}
                  color="primary"
                  sx={{ my: 2 }}
                >
                  <SendIcon />
                </IconButton>
                {isRunning && (
                  <CircularProgress
                    size={40}
                    sx={{
                      color: "primary.main",
                      position: "absolute",
                      top: 16,
                      left: -1,
                      zIndex: 1
                    }}
                  />
                )}
              </Box>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default InputAppBar;
