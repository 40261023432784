import {
  Typography
} from "@mui/material";

// 生成AIモデル一覧
export const modelList = [
  { label: "Gemma 7B / Google", value: "@hf/google/gemma-7b-it" },
  { label: "Llama 3.1 70B / Meta", value: "@cf/meta/llama-3.1-70b-instruct" },
  { label: "Llama 3.2 8B / Meta", value: "@cf/meta/llama-3.2-3b-instruct" },
  { label: "Llama 3.3 70B / Meta", value: "@cf/meta/llama-3.3-70b-instruct-fp8-fast" },
  { label: "Phi 2 / Microsoft", value: "@cf/microsoft/phi-2" },
  { label: "Mistral 7B / Mistral AI", value: "@hf/mistral/mistral-7b-instruct-v0.2" },
  { label: "Qwen 1.5 14B / Alibaba Cloud", value: "@cf/qwen/qwen1.5-14b-chat-awq" }
];

// 画像生成AIモデル一覧
export const imageModelList = [
  { label: "Stable Diffusion XL 1.0 Base / Stability AI", value: "@cf/stabilityai/stable-diffusion-xl-base-1.0" },
  { label: "Stable Diffusion XL 1.0 Lightning / Stability AI", value: "@cf/bytedance/stable-diffusion-xl-lightning" },
  { label: "FLUX.1 schnell / Black Forest Labs", value: "@cf/black-forest-labs/flux-1-schnell" },
  { label: "DreamShaper 8 LCM / Lykon", value: "@cf/lykon/dreamshaper-8-lcm" }
]

const listStyle = {
  paddingLeft: 16
};
// ヘルプに表示する内容
export const helpList = [
  {
    summary: "使い方",
    details: (
      <div>
        <Typography variant="caption">
          <ol style={listStyle}><li>
            設定ボタンをクリックして、生成AIモデルを選択してください。
          </li><li>
              テキストフィールドに文章を入力してください。
            </li><li>
              送信ボタンを押下してください。
            </li><li>
              しばらくすると、文章生成の結果が表示されます。
            </li></ol>
        </Typography>
      </div>
    )
  },
  {
    summary: "プライバシーポリシー",
    details: (
      <div>
        <Typography variant="caption">
          きなこAIチャット（以下「当サイト」といいます。）は、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
          <ol style={listStyle}><li>
            個人情報について
            <ul style={listStyle}><li>
              「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
            </li><li>
                当サイトでは、個人情報を取得することはありません。
              </li><li>
                当サイトは、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
              </li><li>
                利用目的の変更を行った場合には、変更後の目的について、所定の方法により、ユーザーに通知し、または当サイト上に公表するものとします。
              </li></ul>
          </li><li>
              Google Analyticsについて
              <ul style={listStyle}><li>
                当サイトでは、Googleによるアクセス解析ツール「Google Analytics」を使用しています。
              </li><li>
                  このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。
                </li><li>
                  この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
                </li><li>
                  本ポリシーに関しての詳細は<a href='https://marketingplatform.google.com/about/analytics/terms/jp/'>Googleアナリティクス利用規約</a>や<a href='https://policies.google.com/technologies/ads?hl=ja'>ポリシーと規約</a>をご覧ください。
                </li></ul>
            </li><li>
              Google AdSenseについて
              <ul style={listStyle}><li>
                当サイトでは、Googleによる第三者配信の広告サービス「Google AdSense」を使用しています。
              </li><li>
                  Googleなどの第三者配信事業者がCookieを使用して、ユーザーがそのウェブサイトや他のウェブサイトに過去にアクセスした際の情報に基づいて広告を配信します。
                </li><li>
                  Googleが広告Cookieを使用することにより、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて、Googleやそのパートナーが適切な広告をユーザーに表示できます。
                </li><li>
                  ユーザーは、<a href='https://www.google.com/settings/ads'>広告設定</a>でパーソナライズ広告を無効にできます。また、<a href='www.aboutads.info'>www.aboutads.info</a>にアクセスすれば、パーソナライズ広告に使われる第三者配信事業者のCookieを無効にできます。
                </li><li>
                  第三者配信事業者や広告ネットワークの配信する広告がサイトに掲載されます。
                </li><li>
                  対象となる第三者配信事業者や広告ネットワークの適切なウェブサイトへのリンクを掲載します。
                </li><li>
                  配信事業者や広告ネットワークがこの機能を提供している場合、これらのウェブサイトにアクセスすれば、パーソナライズド広告の掲載で使用される Cookie を無効にできます。また、<a href='www.aboutads.info'>www.aboutads.info</a>にアクセスすれば、第三者配信事業者がパーソナライズド広告の掲載で使用するCookieを無効にできます。
                </li></ul>
            </li><li>
              プライバシーポリシーについて
              <ul style={listStyle}><li>
                本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
              </li><li>
                  当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
                </li></ul>
            </li></ol>
        </Typography>
      </div>
    )
  },
  {
    summary: "利用規約",
    details: (
      <div>
        <Typography variant="caption">
          <ol style={listStyle}><li>
            当サイトについて
            <ul style={listStyle}><li>
              個人情報や機密情報などは絶対に入力しないようにしてください。
            </li><li>
                入力された文章は、文章生成以外の目的では使用しません。
              </li><li>
                使用する生成AIモデルの利用規約やプライバシーポリシーを確認してください。
              </li></ul>
          </li><li>
              リンクについて
              <ul style={listStyle}><li>
                当サイトは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。
              </li><li>
                  ただし、インラインフレームの使用や画像の直リンクは禁止します。
                </li></ul>
            </li><li>
              免責事項について
              <ul style={listStyle}><li>
                当サイトの情報や、当サイトのリンクやバナーなどで移動したサイトで提供される情報やサービス等について、損害等の一切の責任を負いません。
              </li><li>
                  できる限り正確な情報を提供するように努めていますが、正確性や安全性を保証するものではありません。
                </li></ul>
            </li><li>
              著作権について
              <ul style={listStyle}><li>
                当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。
              </li><li>
                  当サイトは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がある場合、お問い合わせよりご連絡ください。
                </li></ul>
            </li></ol>
        </Typography>
      </div>
    )
  },
  {
    summary: "お問い合わせ",
    details: (
      <div>
        <Typography variant="caption">
          <ul style={listStyle}><li>
            当サイト： きなこAIチャット
          </li><li>
              運営者： きなこ恐竜
            </li><li>
              連絡先： kinakokyoryu@gmail.com
            </li></ul>
        </Typography>
      </div>
    )
  }
];
