import {
  Tooltip,
  AppBar, Toolbar,
  Typography, IconButton, Avatar
} from "@mui/material";
import {
  Image as ImageIcon,
  TextFields as TextFieldsIcon,
  Settings as SettingsIcon,
  Help as HelpIcon
} from "@mui/icons-material";

interface MainAppBarProps {
  isRunning: boolean;
  setSettingsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHelpDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedModel: string;
  selectedImageModel: string;
  isImage: boolean;
  setIsImage: React.Dispatch<React.SetStateAction<boolean>>;
}

function MainAppBar({
  isRunning,
  setSettingsDialogOpen,
  setHelpDialogOpen,
  selectedModel,
  selectedImageModel,
  isImage,
  setIsImage
}: MainAppBarProps): JSX.Element {

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar>
        <Tooltip arrow
          title="きなこAIチャット"
        >
          <IconButton
            onClick={() => window.location.reload()}
            sx={{ p: 0, mr: 2 }}
          >
            <Avatar
              alt="Kinako"
              src={`${process.env.PUBLIC_URL}/logo192.png`}
            />
          </IconButton>
        </Tooltip>

        <Typography variant="body2"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {isImage ? selectedImageModel : selectedModel}
        </Typography>

        <Tooltip arrow
          title={isImage ? "文章生成" : "画像生成"}
        >
          <IconButton
            onClick={() => setIsImage(!isImage)}
            disabled={isRunning}
            size="large"
            color="inherit"
            edge="end"
            sx={{ mr: { xs: 0, md: 2 } }}
          >
            {isImage ? <TextFieldsIcon /> : <ImageIcon />}
          </IconButton>
        </Tooltip>

        <Tooltip arrow
          title="設定"
        >
          <IconButton
            onClick={() => setSettingsDialogOpen(true)}
            disabled={isRunning}
            size="large"
            color="inherit"
            edge="end"
            sx={{ mr: { xs: 0, md: 2 } }}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow
          title="ヘルプ"
        >
          <IconButton
            onClick={() => setHelpDialogOpen(true)}
            size="large"
            color="inherit"
            edge="end"
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}

export default MainAppBar;
