import { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
import {
  Container, Grid2 as Grid,
  Avatar, Paper
} from "@mui/material";
import {
  SmartToy as SmartToyIcon
} from "@mui/icons-material";

type Role = "system" | "user";
type History = {
  role: Role;
  content: string;
};
interface HistoryListProps {
  historyList: History[];
};

function HistoryList({
  historyList
}: HistoryListProps): JSX.Element {
  // 画面の一番下までスクロールする
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollBottomRef && scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [historyList]);

  return (
    <Container maxWidth="xl"
      sx={{ flexGrow: 1, overflow: "auto", mb: 1 }}
    >
      <Grid container spacing={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "start",
          my: 1
        }}
      >
        {historyList.map((msg, index) => (
          <Grid size={12}
            key={index}
          >
            <Paper
              variant={msg.role === "user" ? "outlined" : "elevation"}
              elevation={msg.role === "user" ? 0 : 3}
              square={msg.role === "user"}
              sx={{ p: 1 }}
            >
              {msg.role !== "user" &&
                <Avatar
                  variant="rounded"
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: "primary.main",
                  }}
                >
                  <SmartToyIcon fontSize="small" />
                </Avatar>
              }
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeExternalLinks]}
                components={{
                  code({ node, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return match ? (
                      <SyntaxHighlighter
                        // @ts-ignore
                        style={vscDarkPlus}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      >
                        {String(children).replace(/\n$/, "")}
                      </SyntaxHighlighter>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  }
                }}
              >
                {msg.content}
              </ReactMarkdown>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <div ref={scrollBottomRef} />
    </Container>
  );
}

export default HistoryList;
